import React, { Fragment } from "react";

 import logo from './logoW.png';
 import logoIn from './linkedinlogo.png';
import './App.css';

function App() {
  return (

    <div className="App">
      <header className="App-header">
      <h3>
          Welcome to Weking.
        </h3>
        <img src={logo} className="App-logo" alt="logo" />
        
      <p>
      Site under construction.
      </p>
        <a
          className="linkedinLink"
          href="https://www.linkedin.com/company/weking"
          rel="noreferrer"
          target="_blank"
        >
        <img src={logoIn} alt="linkedin" />
        </a>
      </header>
    </div>
  );
}

export default App;
